import {
    FetchStreamMessage,
    FetchStreamMessageType,
    IFetchStreamMessageAbort,
    IFetchStreamMessageChunk,
    IFetchStreamMessageError,
    IFetchStreamMessageRequest,
    IFetchStreamMessageResponse,
} from './types';

const isFetchStreamMessage = (message: unknown): message is FetchStreamMessage => {
    return (
        message !== null &&
        typeof message === 'object' &&
        'message' in message &&
        message.message === 'fetch-stream-message'
    );
};

export const isFetchStreamRequestMessage = (message: unknown): message is IFetchStreamMessageRequest => {
    return isFetchStreamMessage(message) && message.type === FetchStreamMessageType.REQUEST;
};

export const isFetchStreamResponseMessage = (message: unknown): message is IFetchStreamMessageResponse => {
    return isFetchStreamMessage(message) && message.type === FetchStreamMessageType.RESPONSE;
};

export const isFetchStreamChunkMessage = (message: unknown): message is IFetchStreamMessageChunk => {
    return isFetchStreamMessage(message) && message.type === FetchStreamMessageType.CHUNK;
};

export const isFetchStreamErrorMessage = (message: unknown): message is IFetchStreamMessageError => {
    return isFetchStreamMessage(message) && message.type === FetchStreamMessageType.ERROR;
};

export const isFetchStreamAbortMessage = (message: unknown): message is IFetchStreamMessageAbort => {
    return isFetchStreamMessage(message) && message.type === FetchStreamMessageType.ABORT;
};
