import React from 'react';
import { Box } from '@chakra-ui/react';
import { EditPanel } from 'answers-core/src/components/EditPanel/EditPanel';
import { dispatch } from 'answers-core/src/redux/core-store';
import { useDirectAnswer } from 'answers-core/src/redux/hooks/answer/direct-answers-hooks';
import { useAppSelector } from 'answers-core/src/redux/hooks/app-hooks';
import { closeDirectAnswerEditModal } from 'answers-core/src/redux/slices/modals/direct-answer-edit-modal-slice';
import { setMinimizeSidebarWidthWithAnimation } from 'answers-core/src/redux/slices/resize/resize-slice';

import styles from './DirectAnswerEditPanel.module.scss';

export const DirectAnswerEditPanel: React.FC = () => {
    const { updateDirectAnswer } = useDirectAnswer();
    const { content } = useAppSelector((state) => state.modals.editDirectAnswer);

    const handleCloseModal = () => {
        dispatch(closeDirectAnswerEditModal());
        dispatch(setMinimizeSidebarWidthWithAnimation());
    };

    const update = async (content: string) => {
        return await updateDirectAnswer(content);
    };

    return (
        <Box className={styles.wrapper}>
            <EditPanel onClose={handleCloseModal} onSubmit={update} initialContent={content} />
        </Box>
    );
};
