import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import { v4 as uuid } from 'uuid';

import i18n from '../../../i18n';
import { IRootState } from '../../../redux/core-store';
import { getToastService } from '../../../services/toast/toast-service';
import { getLogger } from '../../../utils/logger';
import { baseApi } from '../../base-api';
import {
    ApiTagTypes,
    IConversation,
    ICreateConversationResponse,
    IFailureEvent,
    IGetConversationParams,
    IGetConversationResponse,
    IMessage,
    IMessageEvent,
    ISendMessageBody,
    ISendMessageParams,
    MessageRole,
    ResponseStatus,
    StreamFailureReason,
    StreamStatus,
} from '../../types';
import { stream } from '../utils/stream';

import { getLastRunningMessage, getMessagePlaceholder } from './utils';

const logger = getLogger('ChatEndpoint');
export const chatUrl = `${process.env.REACT_APP_CHAT_SERVICE_URL}/conversations`;

const baseApiWithTags = baseApi.enhanceEndpoints({
    addTagTypes: [ApiTagTypes.CONVERSATION],
});

export const chatEndpoint = baseApiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        createConversation: builder.mutation<IConversation, IGetConversationParams>({
            queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
                const { customer_project_id, action_id, apps_version } = args;
                const response = await baseQuery({
                    url: chatUrl,
                    method: 'POST',
                    params: { customer_project_id },
                    body: {
                        action_id,
                        apps_version,
                    },
                });

                const { error, data, meta } = response as QueryReturnValue<
                    ICreateConversationResponse,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    const message = i18n.t('error.api.create-conversation', { ns: 'errors' });

                    if (error.status !== ResponseStatus.UNAUTHORIZED) {
                        getToastService().error(message, meta);
                    }

                    return {
                        error,
                    };
                }
                return {
                    data: data.conversation,
                };
            },
            invalidatesTags: [{ type: ApiTagTypes.CONVERSATION }],
        }),
        getConversation: builder.query<IConversation, IGetConversationParams>({
            queryFn: async (args, _queryApi, _extraOptions, baseQuery) => {
                const { customer_project_id, action_id } = args;
                const response = await baseQuery({
                    url: `${chatUrl}/latest`,
                    params: { customer_project_id, action_id },
                    method: 'GET',
                });

                const { error, data } = response as QueryReturnValue<
                    IGetConversationResponse,
                    FetchBaseQueryError,
                    FetchBaseQueryMeta
                >;

                if (error) {
                    if (error.status !== ResponseStatus.NOT_FOUND) {
                        const message = i18n.t('error.api.get-conversation', { ns: 'errors' });
                        getToastService().error(message);
                    }

                    return { error };
                }
                return { data: data.conversation };
            },
            providesTags: [ApiTagTypes.CONVERSATION],
        }),
        sendMessage: builder.query<IMessage | null, ISendMessageParams>({
            queryFn: () => ({ data: null }),
            providesTags: [ApiTagTypes.CONVERSATION],
            async onCacheEntryAdded(
                args: ISendMessageParams,
                { cacheDataLoaded, cacheEntryRemoved, getState, dispatch }
            ) {
                await cacheDataLoaded;
                const {
                    customer_project_id,
                    conversation_id,
                    action_id,
                    turn_id,
                    controller,
                    content,
                    lang_code,
                    selected_sources,
                    selected_smart_filters_ids,
                    filters,
                } = args;

                const state = getState() as IRootState;
                const {
                    auth: { token },
                    settings: { debugMode },
                } = state;

                if (!token) {
                    logger.warn('No token, will NOT create a Stream');
                    return;
                }

                if (!conversation_id) {
                    logger.warn('No conversation_id');
                    return;
                }

                if (!customer_project_id) {
                    logger.warn('No customer_project_id');
                    return;
                }

                dispatch(
                    chatEndpoint.util.updateQueryData(
                        'getConversation',
                        { action_id, customer_project_id },
                        (draft) => {
                            draft.messages.push(
                                // Append user question
                                {
                                    message_id: uuid(),
                                    turn_id,
                                    content,
                                    role: MessageRole.USER,
                                    status: StreamStatus.DONE,
                                },
                                // Placeholder for the generated assistant message
                                {
                                    message_id: uuid(),
                                    turn_id,
                                    content: '',
                                    is_placeholder: true,
                                    role: MessageRole.ASSISTANT,
                                    status: StreamStatus.RUNNING,
                                }
                            );
                        }
                    )
                );

                const onEvent = (event: IMessageEvent | IFailureEvent | null) => {
                    if (!event) {
                        return;
                    }
                    if ('error' in event) {
                        return onError(event);
                    }
                    // Update the cached data for getConversation
                    dispatch(
                        chatEndpoint.util.updateQueryData(
                            'getConversation',
                            { customer_project_id, action_id },
                            (draft) => {
                                const placeholder = getMessagePlaceholder(draft.messages);

                                // If it's the first chunk, replace the message placeholder with the data
                                if (placeholder) {
                                    placeholder.attributions = event.attributions;
                                    placeholder.footnotes = event.footnotes;
                                    placeholder.content = event.content;
                                    placeholder.created_at = event.created_at;
                                    placeholder.message_id = event.message_id;
                                    placeholder.role = event.role;
                                    placeholder.status = event.status;
                                    placeholder.updated_at = event.updated_at;
                                    placeholder.is_placeholder = false;
                                }
                                const messageToUpdate = draft.messages.find((m) => m.message_id === event.message_id);

                                if (messageToUpdate) {
                                    const contentBeforeUpdate = messageToUpdate.content;
                                    messageToUpdate.content = contentBeforeUpdate + event.content;
                                    messageToUpdate.status = event.status;
                                    messageToUpdate.footnotes = event.footnotes;
                                    messageToUpdate.attributions = event.attributions;
                                }
                            }
                        )
                    );
                };

                const onError = (event: IFailureEvent) => {
                    dispatch(
                        chatEndpoint.util.updateQueryData(
                            'getConversation',
                            { customer_project_id, action_id },
                            (draft) => {
                                const placeholder = getMessagePlaceholder(draft.messages);

                                if (placeholder) {
                                    const content = i18n.t(
                                        `error.chat.${event.reason.toLowerCase().replace('_', '-')}`,
                                        {
                                            ns: 'errors',
                                        }
                                    );
                                    placeholder.message_id = uuid();
                                    placeholder.content = content;
                                    placeholder.is_placeholder = false;
                                    placeholder.status = StreamStatus.FAILED;

                                    return;
                                }

                                const runningMessage = getLastRunningMessage(draft.messages);

                                if (runningMessage) {
                                    const content = i18n.t('error.chat.unknown', {
                                        ns: 'errors',
                                    });

                                    runningMessage.content = content;
                                    runningMessage.is_placeholder = false;
                                    runningMessage.status = StreamStatus.FAILED;

                                    return;
                                }
                            }
                        )
                    );
                };

                const onAbort = () => {
                    dispatch(
                        chatEndpoint.util.updateQueryData(
                            'getConversation',
                            { customer_project_id, action_id },
                            (draft) => {
                                if (draft.messages.length) {
                                    const messageToUpdate = draft.messages[draft.messages.length - 1];
                                    messageToUpdate.status = StreamStatus.ABORTED;
                                    messageToUpdate.is_placeholder = false;
                                }
                            }
                        )
                    );
                };

                const url = new URL(`${chatUrl}/${conversation_id}/messages`);
                url.searchParams.append('customer_project_id', customer_project_id);

                const body: ISendMessageBody = {
                    content,
                    lang_code,
                    selected_sources,
                    selected_smart_filters_ids,
                    filters,
                    turn_id,
                    debug: debugMode,
                };

                const signal = controller.signal;
                signal.addEventListener('abort', onAbort);

                stream<ISendMessageBody, IMessageEvent>(token, url, body, controller, onEvent).catch((error) =>
                    onError({
                        error: JSON.stringify(error),
                        status: StreamStatus.FAILED,
                        reason: StreamFailureReason.UNKNOWN,
                    })
                );

                await cacheEntryRemoved;
                signal.removeEventListener('abort', onAbort);
            },
        }),
    }),
});

export const { useLazySendMessageQuery, useCreateConversationMutation, useGetConversationQuery } = chatEndpoint;
