import { useCallback } from 'react';

import { IMessage, StreamStatus } from '../../api/types';
import { MixpanelEvent } from '../../services/mixpanel/types';
import { parseDirectAnswerTextFootnotes } from '../../utils/direct-answer';
import { sendMetrics } from '../thunks/metrics-thunk';

import { useAppDispatch } from './app-hooks';
import { useRichTextCopy } from './rich-text-copy';

export const useCopyMessage = () => {
    const copyRichText = useRichTextCopy();
    const dispatch = useAppDispatch();

    return useCallback(
        async (message: IMessage, exclude?: Map<string, boolean>) => {
            const { content, status, turn_id, message_id } = message;

            const streamIsNotDone = !!status && status !== StreamStatus.DONE; // status is undefined for cached messages
            if (!content || streamIsNotDone || !turn_id) {
                return;
            }
            let textToCopy = content;

            const { clean } = parseDirectAnswerTextFootnotes(textToCopy);
            if (exclude?.get('attributions')) {
                // copy without any attributions data
                textToCopy = clean;
            }

            await copyRichText(textToCopy);

            dispatch(
                sendMetrics({
                    event: MixpanelEvent.CHAT_MESSAGE_COPY,
                    meta: {
                        turn_id,
                        message_id,
                        status,
                    },
                })
            );
        },
        [dispatch, copyRichText]
    );
};
