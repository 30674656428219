export enum FetchStreamMessageType {
    REQUEST = 'request',
    RESPONSE = 'response',
    CHUNK = 'chunk',
    ERROR = 'error',
    ABORT = 'abort',
}

interface IFetchStreamMessageBase<T extends FetchStreamMessageType = FetchStreamMessageType> {
    message: 'fetch-stream-message';
    type: T;
}

export interface IFetchStreamMessageRequestPayload {
    url: string;
    streamId: string;
    options?: RequestInit;
}

export interface IFetchStreamMessageRequest
    extends IFetchStreamMessageBase<FetchStreamMessageType.REQUEST>,
        IFetchStreamMessageRequestPayload {}

export interface IFetchStreamMessageResponsePayload {
    data: ReadableStream | null;
    options: {
        ok?: boolean;
        status?: number;
        statusText?: string;
        type?: string;
        headers?: Record<string, string>;
    };
}

export interface IFetchStreamMessageResponse
    extends IFetchStreamMessageBase<FetchStreamMessageType.RESPONSE>,
        IFetchStreamMessageResponsePayload {}

export interface IFetchStreamMessageChunkPayload {
    value: string | null;
    done: boolean;
}

export interface IFetchStreamMessageChunk
    extends IFetchStreamMessageBase<FetchStreamMessageType.CHUNK>,
        IFetchStreamMessageChunkPayload {}

export interface IFetchStreamMessageError
    extends IFetchStreamMessageBase<FetchStreamMessageType.ERROR>,
        IFetchStreamMessageResponsePayload {}

export interface IFetchStreamMessageAbortPayload {
    streamId: string;
}

export interface IFetchStreamMessageAbort
    extends IFetchStreamMessageBase<FetchStreamMessageType.ABORT>,
        IFetchStreamMessageAbortPayload {}

export type FetchStreamMessage =
    | IFetchStreamMessageRequest
    | IFetchStreamMessageResponse
    | IFetchStreamMessageChunk
    | IFetchStreamMessageError
    | IFetchStreamMessageAbort;
