import { useCallback, useEffect, useMemo } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import { v4 as uuid } from 'uuid';

import {
    chatEndpoint,
    useCreateConversationMutation,
    useGetConversationQuery,
    useLazySendMessageQuery,
} from '../../../api/endpoints/chat/chat-endpoint';
import { usageMetricsEndpoint } from '../../../api/endpoints/usage-metrics-endpoint';
import { AppsVersion, StreamStatus, UsageMetricsEventType } from '../../../api/types';
import { AppToggle } from '../../../clients/unleash/app-toggle-names';
import { getActiveFilters } from '../../../components/Modals/SourcesFilter/helpers';
import { MixpanelEvent } from '../../../services/mixpanel/types';
import { getActiveSmartFiltersIds, getActiveSources } from '../../../utils/transforms';
import { sendMetrics } from '../../thunks/metrics-thunk';
import { useAppDispatch, useAppSelector } from '../app-hooks';
import { useDatesFilter, useProject, useSmartFilters, useSources } from '../settings-hooks';

const finishedStatus = [StreamStatus.DONE, StreamStatus.FAILED, StreamStatus.ABORTED];

export const useChat = () => {
    const dispatch = useAppDispatch();
    const shouldUseChatFilters = useFlag(AppToggle.CHAT_TAB_FILTERS);
    const { languageCode } = useAppSelector((state) => state.settings);
    const { sources } = useSources();
    const activeSources = getActiveSources(sources);
    const { createDatesFilter } = useDatesFilter();
    const { smartFilters, canUseSmartFilters } = useSmartFilters();
    const { project: customer_project_id } = useProject();

    const { data: conversation, isFetching: isGetConversationLoading } = useGetConversationQuery(
        {
            customer_project_id,
        },
        { skip: !customer_project_id }
    );
    const [createConversation, { isLoading: isCreateConversationLoading }] = useCreateConversationMutation();
    const [triggerSendMessage, { originalArgs }] = useLazySendMessageQuery();

    const setupConversation = useCallback(
        async (action_id?: string, apps_version?: AppsVersion) => {
            await createConversation({ customer_project_id, action_id, apps_version });
        },
        [customer_project_id, createConversation]
    );

    const isGenerating = useMemo(() => {
        if (!conversation || !conversation.messages || conversation.messages.length === 0) {
            return false;
        }
        const lastMessage = conversation?.messages[conversation?.messages.length - 1];
        return lastMessage?.status && !finishedStatus.includes(lastMessage.status);
    }, [conversation]);

    useEffect(() => {
        if (conversation === null) {
            setupConversation();
        }
    }, [conversation, setupConversation]);

    const updateMessage = useCallback(
        (message_id: string, content: string) => {
            dispatch(
                chatEndpoint.util.updateQueryData('getConversation', { customer_project_id }, (draft) => {
                    const messageToUpdate = draft.messages.find((m) => m.message_id === message_id);
                    if (!messageToUpdate) {
                        return;
                    }
                    messageToUpdate.content = content;
                })
            );
        },
        [dispatch, customer_project_id]
    );

    const filters = useMemo(() => {
        if (shouldUseChatFilters) {
            return {
                selected_sources: activeSources,
                selected_smart_filters_ids: canUseSmartFilters ? getActiveSmartFiltersIds(smartFilters) : undefined,
                filters: getActiveFilters({ dates: createDatesFilter() }),
                lang_code: languageCode,
            };
        }
        return {};
    }, [shouldUseChatFilters, activeSources, canUseSmartFilters, smartFilters, languageCode, createDatesFilter]);

    const sendMessage = useCallback(
        async (content: string) => {
            if (!conversation || isGenerating) {
                return;
            }
            const { conversation_id } = conversation;

            const turn_id = uuid();
            const controller = new AbortController();

            dispatch(
                sendMetrics({
                    event: MixpanelEvent.CHAT_TAB_SEND_MESSAGE,
                    meta: {
                        conversation_id,
                        turn_id,
                    },
                })
            );

            await triggerSendMessage({
                content,
                conversation_id,
                customer_project_id,
                turn_id,
                controller,
                ...filters,
            });
        },
        [conversation, isGenerating, dispatch, triggerSendMessage, customer_project_id, filters]
    );

    const clearChat = useCallback(async () => {
        await setupConversation();
    }, [setupConversation]);

    const abortMessage = useCallback(() => {
        const message = conversation?.messages.find((m) => m.status === StreamStatus.RUNNING);

        if (!message) {
            return;
        }

        const meta = {
            conversation_id: conversation?.conversation_id,
            turn_id: message.turn_id,
            message_id: message.message_id,
        };

        dispatch(
            sendMetrics({
                event: MixpanelEvent.CHAT_ABORT_MESSAGE,
                meta,
            })
        );

        dispatch(
            usageMetricsEndpoint.endpoints.sendMetrics.initiate({
                type: UsageMetricsEventType.CHAT_MESSAGE_ABORT,
                meta: {
                    ...meta,
                    output: message.content,
                    timestamp: Date.now(),
                },
            })
        );

        if (originalArgs?.controller) {
            originalArgs.controller.abort();
        } else {
            console.warn('No controller found to abort the message');
        }
    }, [conversation, originalArgs?.controller, dispatch]);

    return useMemo(
        () => ({
            conversation,
            sendMessage,
            clearChat,
            updateMessage,
            setupConversation,
            abortMessage,
            isGenerating,
            isLoading: isGetConversationLoading || isCreateConversationLoading,
        }),
        [
            isGenerating,
            isGetConversationLoading,
            isCreateConversationLoading,
            conversation,
            clearChat,
            updateMessage,
            setupConversation,
            sendMessage,
            abortMessage,
        ]
    );
};
