import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, FlexboxProps } from '@chakra-ui/react';
import useResizeObserver, { UseResizeObserverCallback } from '@react-hook/resize-observer';
import { useFlag } from '@unleash/proxy-client-react';
import cx from 'clsx';
import debounce from 'lodash.debounce';

import { ActionType } from '../../../../api/types';
import { ReactComponent as AskAILogo } from '../../../../assets/icons/ask-ai-logo.svg';
import { AppToggle } from '../../../../clients/unleash/app-toggle-names';
import { useAppSelector } from '../../../../redux/hooks/app-hooks';
import { AddKnowledgeButton } from '../../../AskAiKnowledge/AddKnowledgeButton/AddKnowledgeButton';
import { AskAnExpert } from '../../../AskAnExpert/AskAnExpert';

import styles from './HeaderActionsList.module.scss';

const HANDLE_CHANGE_DIR_DELAY = 25;
const CHANGE_DIR_THRESHOLD = 299;

export type HeaderActionsListProps = {
    answersRenderedCount: number;
    className?: string;
};

export const HeaderActionsList: React.FC<HeaderActionsListProps> = ({ className, answersRenderedCount }) => {
    const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);
    const [flexDir, setFlexDir] = useState<FlexboxProps['flexDir']>('row');
    const shouldShowChatTab = useFlag(AppToggle.CHAT_TAB);
    const shouldShowAddToKb = useFlag(AppToggle.ADD_KNOWLEDGE);
    const actionType = useAppSelector((state) => state.question.action_type);
    const isSearchActionType = actionType === null || actionType === ActionType.SEARCH;

    const setWrapperFlexDir = useCallback(
        (wrapper: HTMLDivElement) => {
            const { width } = wrapper.getBoundingClientRect();

            const wrap = width > CHANGE_DIR_THRESHOLD ? 'row' : 'column';

            setFlexDir(wrap);
        },
        [setFlexDir]
    );

    const handleWrapperRef = useCallback(
        (node: HTMLDivElement | null) => {
            setWrapperRef(node);
            if (node) {
                setWrapperFlexDir(node);
            }
        },
        [setWrapperRef, setWrapperFlexDir]
    );

    const debouncedHandleResize = useRef(
        debounce<UseResizeObserverCallback>((entry) => {
            const wrapper = entry.target as unknown as HTMLDivElement;
            setWrapperFlexDir(wrapper);
        }, HANDLE_CHANGE_DIR_DELAY)
    ).current;

    useResizeObserver(wrapperRef, debouncedHandleResize);

    useEffect(() => {
        return () => {
            debouncedHandleResize.cancel();
        };
    }, [debouncedHandleResize]);

    const actionsToRender: React.ReactElement[] = [];

    if (isSearchActionType && shouldShowAddToKb) {
        actionsToRender.push(<AddKnowledgeButton key="add-knowledge-button" variant="answers-header-action" />);
    }

    if (shouldShowChatTab && answersRenderedCount > 0) {
        actionsToRender.push(
            <AskAnExpert
                icon={<AskAILogo width="20px" height="20px" />}
                key="ask-an-expert-button"
                variant="answers-header-action"
            />
        );
    }

    if (!actionsToRender.length) {
        return null;
    }

    return (
        <Box ref={handleWrapperRef} flexDir={flexDir} className={cx(styles.wrapper, className)}>
            {actionsToRender}
        </Box>
    );
};
