import { v4 } from 'uuid';

import { getLogger } from '../../../utils/logger';
import { normalizeHeaders, normalizeRequestUrl } from '../helpers';

import { FETCH_STREAM_PORT_NAME } from './constants';
import { StreamEventsHandler } from './stream-events-handler';

const logger = getLogger('fetch-stream-content');

export const fetchStreamContent = async (input: RequestInfo | URL, init?: RequestInit) => {
    const url = normalizeRequestUrl(input);
    const { signal: initSignal, ...options } = init ?? {};
    const requestHeaders = normalizeHeaders(options?.headers);
    const streamId = v4();

    // it's safe to initialize port here cos in case of error it will be handled by `fetchEventSource`
    const port = chrome.runtime.connect({ name: FETCH_STREAM_PORT_NAME });
    const handler = new StreamEventsHandler(port, initSignal);

    try {
        const stream = handler.getStream();
        const response = await handler.initStream({
            url,
            streamId,
            options: {
                ...options,
                headers: requestHeaders,
            },
        });

        if (response.options.ok !== true) {
            return Response.error();
        }

        return new Response(stream, response.options);
    } catch (e) {
        logger.error('Failed to handle stream content:', e);
        port.disconnect();

        return Response.error();
    }
};
