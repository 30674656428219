import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';

import { ReactComponent as AskAIDebugIcon } from '../../../assets/icons/ask-ai-debug.svg';
import { ReactComponent as DebugIcon } from '../../../assets/icons/debug.svg';
import { useAppSelector } from '../../../redux/hooks/app-hooks';
import { getToastService } from '../../../services/toast/toast-service';
import { Tooltip } from '../../Tooltips/Tooltip';

interface DebugCopyIDActionProps {
    id?: string;
    variant?: string;
    className?: string;
}

export const DebugCopyIDAction: React.FC<DebugCopyIDActionProps> = ({ id, className, variant }) => {
    const debugMode = useAppSelector((state) => state.settings.debugMode);
    const { t } = useTranslation('translations');

    if (!debugMode || !id) {
        return null;
    }

    const handleCopyIdButtonClick = async () => {
        await navigator.clipboard.writeText(id);
        getToastService().info(t('debug.copy.success'));
    };

    return variant ? (
        <Button
            variant={variant}
            className={className}
            onClick={handleCopyIdButtonClick}
            leftIcon={<AskAIDebugIcon width="20px" height="20px" />}
        >
            {t('button.debug.copy')}
        </Button>
    ) : (
        <Tooltip label={t('button.copy.id')} placement="top-end" hasArrow>
            <DebugIcon onClick={handleCopyIdButtonClick} className={className} />
        </Tooltip>
    );
};
