import React, { useCallback } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import { IAnswerResponse } from 'answers-core/src/api/types';
import { DebugCopyIDAction } from 'answers-core/src/components/Actions/DebugCopyID/DebugCopyIDAction';
import { AnswerWebApp } from 'answers-core/src/components/Answers/Answer/AnswerWebApp';
import { DirectAnswer } from 'answers-core/src/components/Answers/DirectAnswer/DirectAnswer';
import { HeaderActionsList } from 'answers-core/src/components/Answers/List/HeaderActionsList/HeaderActionsList';
import { AnswersListMore } from 'answers-core/src/components/Answers/List/More/AnswersListMore';
import { ForceFeedback } from 'answers-core/src/components/Features/ForceFeedback/ForceFeedback';
import { NoRelevantResultsPlaceholder } from 'answers-core/src/components/Placeholders/NoRelevantResults/NoRelevantResultsPlaceholder';
import { useAnswersList } from 'answers-core/src/hooks/answers/useAnswersList';
import { useAppSelector } from 'answers-core/src/redux/hooks/app-hooks';
import cx from 'clsx';

import { DirectAnswerEditPanel } from '../Answers/DirectAnswer/EditPanel/DirectAnswerEditPanel';
import { InformativeEmptyState } from '../Placeholders/InformativeEmptyState/InformativeEmptyState';

import styles from './AnswersList.module.scss';

export interface IAnswersListProps {
    onClick?: (answer: IAnswerResponse) => void;
}

export const AnswersList: React.FC<IAnswersListProps> = ({ onClick = () => false }) => {
    const { answersToRender, hasDirectAnswer, isEmpty, hasMore, collapsed, toggleCollapse, isLoading } =
        useAnswersList();
    const { visible: isOnDirectAnswerEditMode } = useAppSelector((state) => state.modals.editDirectAnswer);
    const question_id = useAppSelector((state) => state.question.question_id);

    const handleAnswerClick = useCallback(
        (answer: IAnswerResponse) => {
            onClick(answer);
        },
        [onClick]
    );

    const renderList = () => {
        if (isEmpty) {
            return <InformativeEmptyState />;
        }

        return isOnDirectAnswerEditMode ? (
            <DirectAnswerEditPanel />
        ) : (
            <>
                {!answersToRender?.length && !hasDirectAnswer ? (
                    <NoRelevantResultsPlaceholder />
                ) : (
                    <div className={styles.list}>
                        {hasDirectAnswer && <DirectAnswer onAnswerAttributionClick={handleAnswerClick} />}
                        <DebugCopyIDAction id={question_id} variant="answers-header-action" />
                        <HeaderActionsList answersRenderedCount={answersToRender?.length ?? 0} />
                        {answersToRender?.map((answer) => (
                            <AnswerWebApp key={answer.uuid} answer={answer} onClick={handleAnswerClick} />
                        ))}
                    </div>
                )}
                {hasMore && <AnswersListMore collapsed={collapsed} onToggleCollapse={toggleCollapse} />}
                <ForceFeedback className={styles.forceFeedback} />
            </>
        );
    };

    return (
        <div className={cx(styles.wrapper, { [styles.spacing]: !isLoading })}>
            {isLoading ? (
                <Box m="auto">
                    <Spinner />
                </Box>
            ) : (
                renderList()
            )}
        </div>
    );
};
