import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'answers-core/src/redux/hooks/app-hooks';

import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit-answer-action.svg';
import { openDirectAnswerEditModal } from '../../../../../redux/slices/modals/direct-answer-edit-modal-slice';
import { setGrowSidebarWidthWithAnimation } from '../../../../../redux/slices/resize/resize-slice';
import { sendMetrics } from '../../../../../redux/thunks/metrics-thunk';
import { MixpanelEvent } from '../../../../../services/mixpanel/types';
import { Tooltip } from '../../../../Tooltips/Tooltip';
import { IAnswerActionsProps } from '../Actions';

import theme from '../../../../../styles/chakra/theme.module.scss';
import styles from '../Actions.module.scss';

export const DirectAnswerEditAction: React.FC<IAnswerActionsProps> = ({ directAnswer }) => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();

    const handleEditButtonClick = async () => {
        dispatch(openDirectAnswerEditModal(directAnswer.answer));
        dispatch(setGrowSidebarWidthWithAnimation(parseInt(theme.editDirectAnswerWidth)));
        dispatch(
            sendMetrics({
                event: MixpanelEvent.DIRECT_ANSWER_OPEN_EDIT,
            })
        );
    };

    return (
        <Tooltip label={t('button.edit.answer')} placement="top-end" hasArrow>
            <EditIcon onClick={handleEditButtonClick} className={styles.action} />
        </Tooltip>
    );
};
