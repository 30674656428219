import React from 'react';

import { useChat } from '../../../redux/hooks/answer/chat-hook';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/app-hooks';
import { closeChatEditModal } from '../../../redux/slices/modals/chat-edit-model-slice';
import { EditPanel } from '../../EditPanel/EditPanel';

export interface IFormData {
    content: string | undefined;
}

export const ChatEditPanel: React.FC = () => {
    const { message } = useAppSelector((state) => state.modals.chatEdit);
    const { updateMessage } = useChat();

    const dispatch = useAppDispatch();

    const handleCloseModal = () => {
        dispatch(closeChatEditModal());
    };

    const handleFormSubmit = (content: string) => {
        updateMessage(message!.message_id, content);
    };

    return <EditPanel initialContent={message?.content} onSubmit={handleFormSubmit} onClose={handleCloseModal} />;
};
