import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AddToKbIcon } from '../../../../../assets/icons/ask-ai-knowledgebase/add-to-kb-apps-icon.svg';
import { useDirectAnswer } from '../../../../../redux/hooks/answer/direct-answers-hooks';
import { useAppDispatch } from '../../../../../redux/hooks/app-hooks';
import { openAskAiAddKnowledgeModal } from '../../../../../redux/thunks/ask-ai-kb-thunk';
import { isDirectAnswerReady } from '../../../../AskAiKnowledge/AddKnowledgeButton/helper';
import { Tooltip } from '../../../../Tooltips/Tooltip';

import styles from 'answers-chrome-extension/src/components/AppsV2/Actions/Actions.module.scss';

export const AddToKb: React.FC = () => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();
    const { directAnswer, isLoading } = useDirectAnswer();

    const isReady = !!directAnswer && isDirectAnswerReady(directAnswer);

    const handleClick: React.MouseEventHandler<HTMLOrSVGElement> = (e) => {
        e.stopPropagation();

        if (isLoading || !isReady) {
            return;
        }

        const answer = !directAnswer?.is_answerable ? '' : directAnswer?.answer ?? '';

        // pass empty title to prevent using the current question
        dispatch(openAskAiAddKnowledgeModal({ title: '', answer }, {}));
    };

    return (
        <Tooltip label={t('ask-ai-kb.add-to-kb')} placement="top-end" hasArrow>
            <AddToKbIcon onClick={handleClick} className={styles.action} />
        </Tooltip>
    );
};
