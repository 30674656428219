import React from 'react';

import { IMessage } from '../../../../api/types';
import { useAppSelector } from '../../../../redux/hooks/app-hooks';
import { DebugCopyIDAction } from '../../../Actions/DebugCopyID/DebugCopyIDAction';

import { CopyChatAction } from './Copy/CopyMessageAction';
import { EditMessageAction } from './Edit/EditMessageAction';
import { MessageThumbAction, ThumbType } from './Thumb/MessageThumbAction';

import styles from '../ChatMessage.module.scss';
import messageStyles from './MessageActions.module.scss';

interface IMessageActionsProps {
    message: IMessage;
    displayEditButton?: boolean;
}

export const MessageActions: React.FC<IMessageActionsProps> = ({ message, displayEditButton }) => {
    const isUatMode = useAppSelector((state) => state.uat.enabled);

    return (
        <div className={styles.actions}>
            {!isUatMode && <MessageThumbAction message={message} thumbType={ThumbType.ThumbDown} />}
            <MessageThumbAction message={message} thumbType={ThumbType.ThumbUp} />
            <CopyChatAction message={message} />
            <DebugCopyIDAction id={message.turn_id} className={messageStyles.action} />
            {displayEditButton && <EditMessageAction message={message} />}
        </div>
    );
};
