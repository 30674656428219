import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';

import { ReactComponent as AddToKbIcon } from '../../../../../assets/icons/ask-ai-knowledgebase/add-to-kb-apps-icon.svg';
import { useAppDispatch } from '../../../../../redux/hooks/app-hooks';
import { openAskAiAddKnowledgeModal } from '../../../../../redux/thunks/ask-ai-kb-thunk';

export type AddToKbProps = {
    title: string;
    answer: string;
    turn_id: string;
    className?: string;
};

export const AddToKb: React.FC<AddToKbProps> = ({ className, title, answer, turn_id }) => {
    const { t } = useTranslation('translations');
    const dispatch = useAppDispatch();

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();

        dispatch(openAskAiAddKnowledgeModal({ title, answer }, { turn_id }));
    };

    return (
        <Button variant="chat-ask-an-expert" className={className} onClick={handleClick} leftIcon={<AddToKbIcon />}>
            {t('ask-ai-kb.add-to-kb')}
        </Button>
    );
};
