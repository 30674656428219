import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import createDOMPurify from 'dompurify';

import { getToastService } from '../../services/toast/toast-service';
import { markdownToHtml } from '../../utils/markdowns-convertor';

const DOMPurifier = createDOMPurify();
const ALLOWED_TAGS = ['br', 'li'];

const uponSanitizeElement = (node: Element) => {
    if (ALLOWED_TAGS.includes(node.tagName?.toLowerCase()) && node.parentNode) {
        node.outerHTML = '\n' + node.textContent || '';
    }
};

export const useRichTextCopy = () => {
    const { t } = useTranslation('translations');

    return useCallback(
        async (rawData?: string, isMarkdown = true) => {
            if (!rawData) {
                return;
            }

            try {
                // In order to copy answer as rich text, it's required to use html and not markdowns value
                const rawHtml = isMarkdown ? markdownToHtml(rawData) : rawData;
                const richText = DOMPurifier.sanitize(rawHtml, {});
                const htmlType = 'text/html';
                const html = new Blob([richText], { type: htmlType });

                // Generate the plain text to copy
                DOMPurifier.addHook('uponSanitizeElement', uponSanitizeElement);
                const plainText = DOMPurifier.sanitize(richText, {
                    ALLOWED_TAGS,
                    RETURN_DOM: true,
                }).innerText.replaceAll(/\n *!/gi, '\n');
                DOMPurifier.removeHook('uponSanitizeElement');

                // Copy text as rich text
                const plainType = 'text/plain';
                const text = new Blob([plainText], { type: plainType });

                const clipboardItem = new ClipboardItem({ [htmlType]: html, [plainType]: text });

                await navigator.clipboard.write([clipboardItem]);
            } catch (e) {}

            getToastService().info(t('answer.copy.public.success'));
        },
        [t]
    );
};
