import {
    FetchStreamMessageType,
    IFetchStreamMessageAbort,
    IFetchStreamMessageAbortPayload,
    IFetchStreamMessageChunk,
    IFetchStreamMessageChunkPayload,
    IFetchStreamMessageError,
    IFetchStreamMessageRequest,
    IFetchStreamMessageRequestPayload,
    IFetchStreamMessageResponse,
    IFetchStreamMessageResponsePayload,
} from './types';

export const fetchStreamMessagesFactory = {
    createRequestMessage: (payload: IFetchStreamMessageRequestPayload): IFetchStreamMessageRequest => ({
        message: 'fetch-stream-message',
        type: FetchStreamMessageType.REQUEST,
        ...payload,
    }),
    createResponseMessage: (payload: IFetchStreamMessageResponsePayload): IFetchStreamMessageResponse => ({
        message: 'fetch-stream-message',
        type: FetchStreamMessageType.RESPONSE,
        ...payload,
    }),
    createChunkMessage: (payload: IFetchStreamMessageChunkPayload): IFetchStreamMessageChunk => ({
        message: 'fetch-stream-message',
        type: FetchStreamMessageType.CHUNK,
        ...payload,
    }),
    createErrorMessage: (options: IFetchStreamMessageError['options']): IFetchStreamMessageError => ({
        message: 'fetch-stream-message',
        type: FetchStreamMessageType.ERROR,
        data: null,
        options,
    }),
    createAbortMessage: (payload: IFetchStreamMessageAbortPayload): IFetchStreamMessageAbort => ({
        message: 'fetch-stream-message',
        type: FetchStreamMessageType.ABORT,
        ...payload,
    }),
};
